*{
  margin: 0;
  padding: 0;
}
.flex_jc_ac{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexColumn_jc_ac{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.flexColumn{
  display: flex;
  flex-direction: column;
}
.primaryColor{
  color:rgba(214, 5, 106, 1)
}
.primaryBackground{
  background:rgba(214, 5, 106, 1)
}
.bigText{
  font-weight: 600;
  font-size: 22px;
  font-family: Poppins;
}
.mediumText{
  font-weight: 600;
  font-size: 12px; /* Assuming you want to use the value defined in the first search result */
  line-height: 24px;
  font-family: Poppins;
  text-align: center;
}
.textBold{
  font-family: poppins-bold,serif;
  font-weight: bold;

}
.normalText{
  font-weight: 400;
  font-size: 14px; /* Assuming you want to use the value defined in the first search result */
  line-height: 24px;
  font-family: Poppins;
  text-align: center;
}
.img-size {
  width: 180px; /* Default image width */
}

/* Media query for mobile screens */
@media only screen and (max-width: 600px) {
  .img-size {
    width: 40%; /* Set image width to 40% on mobile screens */
  }
  .widthModel{
    width: 10%;
  }
}
.widthModel{
  width: 1200px;
}

.my-container {
  width: 90%;
  text-align: center;
}

/* Styles for smaller screens */
@media (max-width: 767px) {
  .my-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: start;
    padding-top: 10px;
    padding-bottom: 100px;
  }
}
.noResponsiveContainer{
  width: 90%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: start;
  padding-top: 10px;
  padding-bottom: 100px;
}

::-webkit-scrollbar {
  width: 0;
}
/* Styles for larger screens */
@media (min-width: 768px) {
  .my-container {
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    display: flex;
    column-gap: 10px;
  }
}
.logoCertigna{
  width: 137px;
  height: 32px;
}
