body {
  margin: 0;
  font-family: poppins,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: poppins;
  src: url(../public/poppins/Poppins-Regular.ttf)
}
@font-face {
  font-family: poppins-bold;
  src: url(../public/poppins/Poppins-Bold.ttf)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.backgroundImage {
  background-image: url(asset/3d-background.png);
  background-color: #fafbfe;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 265px;
  padding: 40px 24px 40px 24px;
  background-position: right;
}
/* Media query for screens larger than or equal to 812px in height */
@media only screen and (min-height: 812px) {
  .backgroundImage {
    height: 300px;
  }
}
::-webkit-scrollbar {
  width: 0;
}
/*className={'backgroundImage'}*/
/*width={'100%'}*/
/*height={'265px'}*/
/*padding={'40px, 24px, 40px, 24px'}*/
::-webkit-scrollbar {
  width: 0;
}

.titleText{
  font-size: 1.375rem;
}

.subTitle{
  font-size: 1.125rem;
}

.popupText{
  font-size: 1.125rem;
}

.contentText{
  font-size: 0.875rem;
}

.minContentText{
  font-size:0.75rem;
}
.btnMainText{
  font-size: 1rem;
}
.bigTitleText{
  font-size: 1.9375rem;
}