.containerModel{
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
}
.containerFullScreen{
    width: 100vw;
    height: 100vh;
}
.titleText{
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
}
.containerBody{
    width: 90%;
    text-align: center;
    gap: 9px;
}
@media only screen and (max-width: 767.98px) {
    /* CSS for screens smaller than 768px */
    .containerModel {
        position: absolute;
        top: 30vh;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;

    }
}
.containerModelV2{
    padding: 0 33px 33px 33px;
    border-radius: 12px;
    background-color: white;
}
.containerBodyV2{
    width: 483px;
}
@media screen and (max-width: 767px) {
    .containerModelV3{
        position: absolute;
        top: 30vh;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }
    .containerBodyV3{
        width: 90%;
    }
}
@media screen and (max-height: 751px) {
    .containerModelV3 {
        top: 40vh;
    }
}
.containerModelV3{
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
}
.containerBodyV3{
    max-width: 483px;
    padding: 0 33px 44px 33px;
}