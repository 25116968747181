

@media (max-width: 768px) {
    .element_for_desktop {
        display: none;
    }
}
@media only screen and (min-width: 768px) {
    .element_for_phone {
        display: none;
    }
}
@media only screen and (min-width: 769px) {
    .footerText {
        width: 100%;
    }
}
.footerText {
    width: 80%;
}
.titleDrawer{
    text-align: center;
    font-weight: 500;
    line-height: 26px;
    color: black;
    gap: 2px;
}
.textCheckBox{
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
}
.btnTextInDrawer{
    font-size: 16px;
}
@media screen and (max-height: 751px) {
    .titleDrawer {
        font-size: 16px;
        line-height: 20px;
    }
}